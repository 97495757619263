import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-domain',
  templateUrl: './verify-domain.component.html',
  styleUrls: ['./verify-domain.component.css']
})
export class VerifyDomainComponent implements OnInit {

  userForm: FormGroup;
  token: string | null | undefined;
  appId: string | null | undefined;
  appData: any;
 

  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private formBuilder: FormBuilder
   , private cookieService: CookieService
   , private dialog: MatDialog
   ) {
     this.userForm = formBuilder.group({
       email: new FormControl('', [Validators.required, Validators.email])
     })
   }
 
   ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
      this.appId = params.get('appid');
     
      const safeToken = this.token || '';
      this.setToken(safeToken);

      this.getEmail();
    });
   }

  setToken(token: string): void {
    this.cookieService.set('token', token);
  }
 
   formValidation(tag: number){
     let email = this.userForm.get('email')?.value;
     if(!email){
       if(tag==1){
         this._api.openSnackBar('Enter Email', 'Error');
       }
       return;
     }
   this.submitDomainVerification();
   }

 getEmail(){
  this.spinner.show();
  const apiData = {
    instance_id: this.appId,
    token: this.token,
  };
  this._api.functionPOST('web/getEmailByAppId', apiData).subscribe((response) => {

    this.appData = response['data'];
    if(response['success']){
      if(this.appData.is_verified){
        const dialogRef = this.dialog.open(BasicPopupComponent, {
          maxWidth: '600px'
          , data: {
            pageTitle: "Alert",
            pageType: 'email_verified',
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['/']);
          }
        });
      }else{
        this.userForm.patchValue({
          email: this.appData.email
        })
        
      }
  }
  this.spinner.hide();
  });
}

submitDomainVerification(){
    let email = this.userForm.get('email')?.value;
    this.spinner.show();
    const apiData = {
      instance_id: this.appId,
      email: email,
      latitude: this.cookieService.get('latitude'),
      longitude:  this.cookieService.get('longitude')

    };
    this._api.functionPOST('web/validateEmail', apiData).subscribe((response) => {
    this.cookieService.set('instance_id', response['data']['df_instance_id']);
    this.cookieService.set('email', response['data']['email']);
    this.cookieService.set('token', response['data']['token']);

    if(response['data']['token']){
      this.router.navigate(['selected-application/'+response['data']['df_instance_id']]);
    }else {
      this._api.openSnackBar(response['errorDescription'], 'Error');
    }
    this.spinner.hide();
    });
 }
 
}
