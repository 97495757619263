import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-phone-mask-type',
  template: `
 <mat-form-field>
   <mat-label>{{ to.label }}</mat-label>
   <input
      matInput
      [formControl]="formControl"
      [formlyAttributes]="field"
      [id]="id"
      [type]="type"
      [mask]="to.mask"
    />
    <ng-container #fieldComponent></ng-container>
      <button mat-icon-button matSuffix *ngIf="to['tooltip']" [matTooltip]="to['tooltip']" tabindex="-1">
        <mat-icon>info_outline</mat-icon>
      </button>
    <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
</mat-form-field>
  `
})
export class PhoneMaskType extends FieldType {
    @ViewChild(MatInput, { static: true }) matInput!: MatInput;

    get type() {
      return this.to.type || 'text';
    }
  
    get mask() {
      return this.to['mask'] || '(000) 000-0000' || '00-00000000';
    }
  
    get prefix() {
      return this.to['prefix'] || '';
    }

}
