<section class="section">   
    <div class="container mt-3 mb-5">
        <div class="row ">
            <div class="col-md-6 offset-md-3">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Application Type:</strong> <label class="fs-5">{{ statusData.application_name }}</label>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Status:</strong> 
                                    <label class="fs-5">{{ statusData.status_name }}</label>
                                </div>
                                <!-- <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Comments:</strong> <label class="fs-5"></label>
                                </div> -->
                                <div class="col-md-12 mb-3">
                                    <strong class="fs-5 me-3">Last Updated:</strong> <label class="fs-5">{{ statusData.updated | date :'MMM dd, yyyy hh:mm:ss a' }}</label>
                                </div>
                            </div>
                                  
                        </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>
