import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-capture-image-from-camera',
  templateUrl: './capture-image-from-camera.component.html',
  styleUrls: ['./capture-image-from-camera.component.css']
})
export class CaptureImageFromCameraComponent implements AfterViewInit {
  @ViewChild('video', { static: true }) video!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: true }) canvas!: ElementRef<HTMLCanvasElement>;

  captures: string[] = [];
  isFrontCamera: boolean = false;
  stream: MediaStream | null = null;
  capturedImage: string;
  pageType: any;
  pageTitle: any;
  isTakePhoto: boolean = false;
  latitude: string;
  longitude: string;
  timestamp: string;

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

  ngAfterViewInit() {
    this.startCamera();
  }

  // site visite camera
  startCamera() {
    if (!this.video || !this.video.nativeElement) {
      console.error('Video element is not available.');
      return;
    }

  const facingMode = this.isFrontCamera ? "user" : "environment";
  const constraints = {
    video: { facingMode: facingMode, width: 640, height: 480 },
    audio: false
  };

  // Stop existing stream if already running
  if (this.stream) {
    this.stopCamera();
  }

  // Access user's camera
  navigator.mediaDevices.getUserMedia(constraints)
    .then((stream) => {
      this.stream = stream;
      this.video.nativeElement.srcObject = stream;
      this.isTakePhoto = false;
    })
    .catch((error) => {
      console.error('Error accessing media devices.', error);
      alert('Camera access is required for this functionality.');
    });
}

stopCamera() {
  if (this.stream) {
    this.stream.getTracks().forEach(track => track.stop());
    this.stream = null;
    this.isTakePhoto = false;
  }
}

toggleCamera() {
  this.isFrontCamera = !this.isFrontCamera;
  this.startCamera();
}

capture(): void {
  const canvas = this.canvas.nativeElement;
  const video = this.video.nativeElement;

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  const context = canvas.getContext('2d');
  if (context) {
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  }

  this.capturedImage = canvas.toDataURL('image/png');
  this.captures.push(this.capturedImage);

  this.stopCamera();

  // Replace video feed with the captured image
  video.srcObject = null; // Clear the video stream
  video.poster = this.capturedImage; // Use the captured image as the placeholder
  this.isTakePhoto = true;

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const geoLocation = {
          latitude: position.coords.latitude.toFixed(6),
          longitude: position.coords.longitude.toFixed(6)
        };
        const timestamp = new Date().toISOString();
        this.latitude = geoLocation.latitude;
        this.longitude = geoLocation.longitude;
        this.timestamp = timestamp;
        
      },
      (error) => {
        
      }
    );
  } else {
    
  }
}

get toggleButtonText(): string {
  return this.isFrontCamera ? 'Switch to Back Camera' : 'Switch to Front Camera';
}

  sendImage() {
    this.stopCamera();
    const result = {
      latitude:this.latitude,
      longitude: this.longitude,
      timestamp:  this.timestamp,
      image: this.capturedImage,
      section_type: this.data['message']
    }
    this.dialogRef.close(result);
  }

  closeModal() {
    this.stopCamera();
    this.dialogRef.close();
  }

}
