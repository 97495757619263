<div class="form-group">
  <label [innerHTML]="to.label" [ngClass]="{ 'required-label': to.required }"></label>
</div>

<mat-checkbox
    *ngFor="let option of to.options"
    [value]="option.value"
    [class]="to.className"  
    (change)="onChange(option, $event)"
    [checked]="isChecked(option)">

    <!-- <ng-container *ngIf="to.label">
      <label [attr.for]="id">{{ to.label }}</label>
    </ng-container> -->

    <ng-container *ngIf="to.label">
      {{ option.label }}
    </ng-container>
    
  </mat-checkbox>
  <div *ngIf="showError && formControl.invalid" class="invalid-feedback">
  <formly-validation-message [field]="field"></formly-validation-message>
</div>