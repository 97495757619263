import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-column',
  template: `
    <div class="form-column" fxFlex="1">
      <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
    </div>
  `,
  styles: [`
    .form-column {
      padding-right: 10px;
    }
    .form-column:last-child {
      padding-right: 0;
    }
  `]
//   template: `
//     <div class="form-column">
//       <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
//     </div>
//   `,
//   styles: [`
//     .form-column {
//       flex: 1;
//       padding-right: 100px;
//     }
//     .form-column:last-child {
//       padding-right: 0;
//     }
//   `]
})
export class FormlyCustomColumn extends FieldType {}
