import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

 year = new Date().getUTCFullYear();
 version: any;

 constructor(
  private _api: ApiService
  , private spinner: NgxSpinnerService
 ) { }

 ngOnInit() {
  this.version = sessionStorage.getItem('version');
 }
 
}
