import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-application-cancel',
  templateUrl: './application-cancel.component.html',
  styleUrls: ['./application-cancel.component.css']
})
export class ApplicationCancelComponent implements OnInit {
  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private formBuilder: FormBuilder
   , private cookieService: CookieService
   , private dialog: MatDialog
   ) {
     this.userForm = formBuilder.group({
       motherSirName: new FormControl('', [Validators.required]),
       lastName: new FormControl('', [Validators.required]),
       email: new FormControl('', [Validators.required, Validators.email])
     })
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.cookieService.set('instance_id',  params.get('id'));
    });
  }

  formValidation(tag: number){
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;
    if(!lastName){
      if(tag==1){
        this._api.openSnackBar('Enter Last Name', 'Error');
      }
      return;
    }
    if(!email){
      if(tag==1){
        this._api.openSnackBar('Enter Email', 'Error');
      }
      return;
    }
    if(!motherSirName){
      if(tag==1){
        this._api.openSnackBar("Enter Mother's Maiden Name", 'Error');
      }
      return;
    }
  this.submitAccessVerification();
  }

  submitAccessVerification(){
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;
    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      lastname: lastName,
      email: email,
      mother_maiden_name: motherSirName,
      latitude: this.cookieService.get('latitude'),
      longitude:  this.cookieService.get('longitude')
    };
    this._api.functionPOST('web/authendicateUser', apiData).subscribe((response) => {
    if(response['success']){
      this.cookieService.set('instance_id', response['data']['df_instance_id']);
      this.cookieService.set('token', response['data']['token']);
      this.cookieService.set('email', response['data']['email']);

      const dialogRef = this.dialog.open(BasicPopupComponent, {
        maxWidth: '600px'
        , data: {
          pageTitle: "Reason",
          pageType: 'cancel_application',
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.cancelApplication(result);
        }
      });
      
    }
    this.spinner.hide();
    });
  }

  goToHome(){
    this.router.navigate(['/']);
  }

  cancelApplication(reason: any) {
    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      email: this.cookieService.get('email'),
      comment:  reason,
      latitude: this.cookieService.get('latitude'),
      longitude:  this.cookieService.get('longitude')
    };
    this._api.functionPOST('web/cancelApplication', apiData).subscribe((response) => {
    if(response['success']){
      this.goToHome();
      // this.router.navigate(['application-status/'+this.cookieService.get('instance_id')]);
    } else {
      this.goToHome();
    }
    },
    err => {
      this.goToHome();
    });
    this.spinner.hide();
  }
}

