<div class="container-fluid head_wrap">
  <div class="d-flex align-items-center ">
    <div class="logo">
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- src="assets/images/logo.png" -->
          <a href="/">
            <img id="dynamicLogo" alt="" class="dynamic-logo logo-icon">
          </a>
        </div>
        <div class="col mt-3">
          <h1 id="dynamicHeader"></h1>
        </div>
      </div>
    </div>
   
  </div>
</div>
