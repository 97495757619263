import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-textarea',
  template: `
    <label *ngIf="to.label" class="custom-label">{{ to.label }}<span class='text-danger'> *</span></label>
    <mat-form-field appearance="fill" class="custom-material-input">
      <!-- <mat-label *ngIf="to.label">{{ to.label }}</mat-label> -->
      <textarea
        matInput
        [formControl]="formControl"
        [formlyAttributes]="field"
        placeholder="Enter your comments here"
        rows="5"
    ></textarea>
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
    ::ng-deep .mat-form-field {
        width: 100% !important;
      }
    `,
  ],
})
export class FormlyCustomTextareaComponent extends FieldType {}
