<div class="modal-container">
    <div class="modal-header">
        <h3>Capture Photo</h3>
        <button mat-icon-button (click)="closeModal()" class="mb-2">
            <mat-icon>close</mat-icon></button>
        </div>
            <div class="row">
                <div class="col-md-12" style="display: flex;
                align-items: center;
                justify-content: center;">
                    <button mat-raised-button id="toggle" class="blue_button mb-2" (click)="toggleCamera()">{{ toggleButtonText }}</button>
                </div>
            </div>
            <div id="app">
                <div>
                  <video #video id="video" style="width: 100%; height: auto;" autoplay playsinline></video>
                </div>
                <div style="display: flex;
                align-items: center;
                justify-content: center;">
                  <button mat-raised-button id="snap" class="" color="secondary" (click)="startCamera()">Retake</button>
                  <button *ngIf="!isTakePhoto" mat-raised-button id="snap" class="blue_button ms-3" (click)="capture()">Take photo</button>
                  <button *ngIf="isTakePhoto" mat-raised-button id="snap" class="blue_button ms-3" (click)="sendImage()">Use this photo</button>
                </div>
                <canvas #canvas id="canvas" height="480" style="display: none; width: 100%;"></canvas>
            
            </div>
        

</div>
        
        