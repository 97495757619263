<!-- <div>
    <label [for]="id" *ngIf="to.label">{{ to.label }}</label>
    <div #signatureContainer class="signature-container" style="border: 1px solid #ccc; width: 100%; height: 200px;">
      <canvas #signaturePadCanvas></canvas>
    </div>
    <button type="button" class="btn btn-sm btn-danger mt-2" (click)="clearSignature()">Clear</button>
    <button type="button" class="btn btn-primary" (click)="uploadSignature()">Upload Signature</button>
  </div> -->
  <label class="mb-2" [for]="id" *ngIf="to.label">{{ to.label }}</label>

  <label class="mb-2" *ngIf="to.description" hidden>{{ to.description }}</label>
  <!-- <mat-checkbox class="example-margin mt-3 mb-3">I confirm</mat-checkbox><br> -->
  <label class="mb-2 mt-2 fw-bold fs-6">Signature</label>
  <div class="signature-container">
   
    <canvas #signaturePadCanvas></canvas>
    <div class="signature-actions">
      <button type="button" class="btn btn-danger" (click)="clearSignature()">Clear</button>
      <!-- <button type="button" class="btn btn-primary" (click)="uploadSignature()">Upload</button> -->
      <button type="button" class="btn btn-primary" (click)="triggerUpload()">
        {{ to.uploadButtonText || 'Upload' }}
      </button>
    </div>
  </div>