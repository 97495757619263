<footer class="mt-auto">
  <div class="d-flex flex-wrap h-100 align-content-end align-items-baseline">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
      <p class="text-small"><em>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" class="">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" class="">Terms of Service</a> apply.</em></p>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" id="dynamicFooter">
    </div>
  </div>
  
</footer>
