<section class="mt-5">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of dynamicFormList" class="col-sm-4 col-xs-12 col-lg-4 col-md-4 mb-4">
                <mat-card class="cust_card bg-aliceblue">
                    <mat-card-content class="text-center">
                        <h4 class="bold">
                            {{ item.name }}
                        </h4>
                        <p>
                            {{ item.description }}
                        </p>
                        <button class="blue_button" mat-raised-button (click)="goToApp(item.df_template_id)">Go to application</button>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>