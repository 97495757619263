import { Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";

@Component({
    selector: 'formly-field-expansion-panel',
    template: `
     <!-- <div class="row text-center">
                <div class="col-md-3 mb-4"><strong>Document Type</strong></div>
                <div class="col-md-3 mb-4"><strong>Status</strong></div>
                <div class="col-md-3 mb-4"><strong>Comments</strong></div>
                <div class="col-md-3 mb-4"><strong>Updated Date</strong></div>
      </div> -->
      <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" (opened)="openPanel()" (closed)="closePanel()" hideToggle>

      <mat-expansion-panel-header>
            <mat-panel-title>
              Business Income Tax Returns
            </mat-panel-title>
            <mat-panel-description>
            <div class="badge bg-success p-2">Uploaded</div>
              <!-- What are you sharing? -->
              <!-- <mat-icon class="text-success">Uploaded</mat-icon> -->
            </mat-panel-description>
          </mat-expansion-panel-header>

        <!-- <mat-expansion-panel-header>
          <mat-panel-title>{{ to.title }}</mat-panel-title>
          <mat-panel-description>{{ to.description }}</mat-panel-description>
        </mat-expansion-panel-header> -->
        <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>

        <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>

      </mat-expansion-panel>
      </mat-accordion>


      <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle (opened)="openPanel()" (closed)="closePanel()">

<mat-expansion-panel-header>
      <mat-panel-title>
        Step 1
      </mat-panel-title>
      <mat-panel-description>
        What are you sharing?
        <mat-icon>share</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

  <!-- <mat-expansion-panel-header>
    <mat-panel-title>{{ to.title }}</mat-panel-title>
    <mat-panel-description>{{ to.description }}</mat-panel-description>
  </mat-expansion-panel-header> -->
  <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
  <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
</mat-expansion-panel>
      </mat-accordion>
    `,
    styles: [`
        ::ng-deep .mat-expansion-panel {
          margin-bottom: 10px !important;
        }
        ::ng-deep .mat-expansion-panel-header-description{
            color: #fff !important;
            padding: 10px !important;
            font-weight: 700 !important;
            font-size: 20px !important;
        }
        ::ng-deep .mat-expansion-panel-header-title{
            font-weight: 700 !important;
        }
        .img {
  width: 100%;
  margin-bottom: 18%;
}
.card-picture {
  width: 23%;
  margin-bottom: 2%;
}
.container {
  min-height: 100%;
  width: 100%;
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

::ng-deep .example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

mat-form-field {
  margin-right: 12px;
}

::ng-deep .example-small-box, .example-large-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
}

.example-small-box {
  height: 100px;
  width: 100px;
}

.example-large-box {
  height: 300px;
  width: 300px;
}

.map-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  background-color:lightblue;
}

.media-box {
  height: 100px;
  width: 100px;
  background-color:lightgreen;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
}

.example-full-width {
   width: 100%;
}

      `]
  })
  export class ExpansionPanelTypeComponent extends FieldType {

    locationControl = new FormControl();
    locationList: string[] = ['Add a Public Location', 'Add Private Location', 'Multi-Location'];
    keywordControl = new FormControl();
    keywordList: string[] = ['Bike', 'Skates', 'Computer'];
    requirementControl = new FormControl();
    requirementsList: string[] = ['No Requirements', 'ID', 'Insurance', 'Background Check', 'Other'];
    value = 'My Awesome Title';
    step = 0;
    selectedshare = 'Stay';
    requirements: FormGroup;
    setStep(index: number) {
      this.step = index;
    }
  
    nextStep() {
      this.step++;
    }
  
    prevStep() {
      this.step--;
    }
  
    onFileComplete(data: any) {
      console.log(data); 
    }
    
    openPanel() {
      this.formControl.setValue(true);
    }
    
    closePanel() {
      this.formControl.setValue(false);
    }
  }
  