import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-application-status',
  templateUrl: './application-status.component.html',
  styleUrls: ['./application-status.component.css']
})
export class ApplicationStatusComponent implements OnInit {
  statusData: any;

  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private cookieService: CookieService
   ) {}

  ngOnInit(): void {
    this.getAppStatus();
  }


  getAppStatus() {
    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      email: this.cookieService.get('email'),
    };
    this._api.functionPOST('web/getApplicationStatus', apiData).subscribe((response) => {
    if(response['success']){
      this.statusData = response['data'];
    }
    this.spinner.hide();
    });
  }

}
