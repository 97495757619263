import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { AbstractControl, FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CommonModule } from '@angular/common';
import { Materials } from './material';
import { FormlyCustomCheckboxComponent } from './shared/formly-custom-checkbox/formly-custom-checkbox.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormlyCustomTabs } from './shared/utils/formly-custom-tabs';
import { FormlyCustomSection } from './shared/utils/formly-custom-section';
import { CustomCameraComponent } from './shared/utils/formly-field-custom-camera';
import { FormlyCustomRow } from './shared/utils/formly-custom-row';
import { FormlyCustomColumn } from './shared/utils/formly-custom-column';
import { FormlyCustomButton } from './shared/utils/formly-custom-button';
import { FormlyCustomMultiSelectComponent } from './shared/utils/formly-custom-multi-select';
import { FormlyCustomInputComponent } from './shared/utils/formly-custom-input';
import { FormlyCustomTextareaComponent } from './shared/utils/formly-custom-textarea';
import { FormlyAccordionTypeComponent } from './shared/utils/formly-field-custom-accordion';
import { FormlyCustomIndividualTable } from './shared/utils/formly-custom-individual-table';
import { FormlyCustomRadio } from './shared/utils/formly-custom-radio';
import { FormlyTooltipWrapper } from './shared/utils/formly-tooltip-wrapper';
import { FormlyFieldCheckboxComponent } from './shared/utils/formly-field-checkbox';
import { PhoneMaskType } from './shared/utils/phone-mask-type';
import { CustomThumbnailsComponent } from './shared/utils/formly-field-custom-thumbnails';
import { FormlyCustomTable } from './shared/utils/formly-custom-table';
import { FormlyFileCustom } from './shared/utils/formly-custom-file';
import { ImageTypeComponent } from './shared/utils/formly-field-image';
import { FormlyDynamicModalComponent } from './shared/popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { UnAuthComponent } from './shared/popups/un-auth/un-auth.component';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserVerificationComponent } from './user-verification/user-verification.component';
import { VerifyDomainComponent } from './verify-domain/verify-domain.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CookieService } from 'ngx-cookie-service';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { DynamicApplicationComponent } from './dynamic-application/dynamic-application.component';
import { DynamicApplicationDemoComponent } from './dynamic-application-demo/dynamic-application-demo.component';
import { ExpansionPanelTypeComponent } from './shared/utils/formly-field-expansion-panel';
import { AccordionTypeComponent } from './shared/utils/accordion-type.component';
import { ApplicationAccessVerificationComponent } from './application-access-verification/application-access-verification.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { BasicPopupComponent } from './shared/popups/basic-popup/basic-popup.component';
import { HeaderService } from './services/header.service';
import { FormlyCustomLicenseTable } from './shared/utils/formly-custom-lic-table';
import { FormlyCustomDocTable } from './shared/utils/formly-custom-doc-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ResponsiveDemoComponent } from './responsive-demo/responsive-demo.component';
import { ApplicationCancelComponent } from './application-cancel/application-cancel.component';
import { SiteVisitComponent } from './site-visit/site-visit.component';
import { SiteVisitInspectionComponent } from './site-visit-inspection/site-visit-inspection.component';
import { CustomSignatureComponent } from './custom-signature/custom-signature.component';
import { CaptureImageFromCameraComponent } from './shared/popups/capture-image-from-camera/capture-image-from-camera.component';

// export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

export function minLengthValidationMessages(error: any, field: FormlyFieldConfig) {
  return `Should have atleast ${field.props?.minLength} characters`;
}

export function floatValidator(control: FormControl) {
  const value = control.value;
  const regex = /^[0-9]*\.?[0-9]+$/; // Regex to match floating-point numbers
  return regex.test(value) ? null : { invalidFloat: true }; // Return error if invalid
}

export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}

export function animationExtension(field: FormlyFieldConfig) {
  if (field.wrappers && field.wrappers.includes('animation')) {
    return;
  }

  field.wrappers = ['animation', ...(field.wrappers || [])];
}

export function loadHeaderData(headerService: HeaderService) {
  return () => headerService.getHeaderData().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    FormlyCustomCheckboxComponent,
    FormlyCustomTabs,
    FormlyCustomSection,
    FormlyCustomRow,
    FormlyCustomColumn,
    FormlyCustomButton,
    FormlyCustomInputComponent,
    FormlyCustomMultiSelectComponent,
    FormlyCustomTextareaComponent,
    FormlyAccordionTypeComponent,
    FormlyCustomIndividualTable,
    FormlyCustomRadio,
    FormlyTooltipWrapper,
    CustomThumbnailsComponent,
    PhoneMaskType,
    FormlyCustomTable,
    FormlyFileCustom,
    CustomCameraComponent,
    ImageTypeComponent,
    FormlyDynamicModalComponent,
    UnAuthComponent,
    UserVerificationComponent,
    VerifyDomainComponent,
    DynamicApplicationComponent,
    DynamicApplicationDemoComponent,
    AccordionTypeComponent,
    ExpansionPanelTypeComponent,
    ApplicationAccessVerificationComponent,
    ApplicationStatusComponent,
    BasicPopupComponent,
    FormlyCustomLicenseTable,
    FormlyCustomDocTable,
    ResponsiveDemoComponent,
    ApplicationCancelComponent,
    SiteVisitComponent,
    SiteVisitInspectionComponent,
    CustomSignatureComponent,
    CaptureImageFromCameraComponent,
    FormlyFieldCheckboxComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    AppRoutingModule,
    Materials,
    NgxMaskModule.forRoot({thousandSeparator: ','}),
    FormlyMaterialModule,
    NgxDatatableModule,
    FormlyModule.forRoot({
      
      validators: [
        { name: 'fieldMatch', validation: fieldMatchValidator }, 
        { name: 'float', validation: floatValidator }
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minLength', message: minLengthValidationMessages },
      ],
      types: [
        { name: 'tabs', component: FormlyCustomTabs },
        { name: 'section', component: FormlyCustomSection },
        { name: 'row', component: FormlyCustomRow },
        { name: 'column', component: FormlyCustomColumn },
        { name: 'custom-button', component: FormlyCustomButton },
        { name: 'custom-input', component: FormlyCustomInputComponent },
        {
          name: 'custom-multi-select',
          component: FormlyCustomMultiSelectComponent,
        },
        {
          name: 'custom-textarea',
          component: FormlyCustomTextareaComponent,
        },
        { name: 'custom-checkbox', component: FormlyFieldCheckboxComponent }, 
        { name: 'multi-checkbox', component: FormlyCustomCheckboxComponent },
        { name: 'table', component: FormlyCustomTable },
        { name: 'individual-table', component: FormlyCustomIndividualTable },
        { name: 'lic-table', component: FormlyCustomLicenseTable },
        { name: 'datatable', component: FormlyCustomDocTable,
          defaultOptions: {
            templateOptions: {
              columnMode: 'force',
              rowHeight: 'auto',
              headerHeight: '40',
              footerHeight: '40',
              limit: '10',
              scrollbarH: 'false',
              scrollbarV: 'true',
              reorderable: 'reorderable',
            },
          },
         },
        { name: 'accordion', component: AccordionTypeComponent },
        { name: 'site-visit-accordion', component: FormlyAccordionTypeComponent },
        // { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'file', component: FormlyFileCustom },
        {
          name: 'custom-camera',
          component: CustomCameraComponent,
        },
        {
          name: 'custom-thumbnails',
          component: CustomThumbnailsComponent,
        },
        { name: 'custom-signature', component: CustomSignatureComponent },
        { name: 'image', component: ImageTypeComponent },
        { name: 'phone', component: PhoneMaskType,
          defaultOptions: {
            templateOptions: {
              type: 'tel',
            },
          },
        },
        {
          name: 'text',
          extends: 'phone',
          defaultOptions: {
            templateOptions: {
              type: 'text',
            },
          },
        },
        { name: 'custom-radio', component: FormlyCustomRadio },
        { name: 'expansionPanel', component: ExpansionPanelTypeComponent },
      ],
      wrappers: [
        { name: 'tooltip', component: FormlyTooltipWrapper },
      ],
     
    }),
  ],
  // providers: [CookieService],
  providers: [
    HeaderService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadHeaderData,
      deps: [HeaderService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
