import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core'

@Component({
  selector: 'app-formly-custom-button',
  template: `
    <button
      mat-raised-button
      type="button"
      [class]="to.className"
      [color]="to.color"
      [disabled]="to.disabled || false"
      (click)="onClick($event)">
      {{ to['text'] }}
    </button>
  `,
  styles: [
    `
     ::ng-deep button{
      margin-top: 15px !important;
     }
     ::ng-deep .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
        background-color: #0d6efd !important;
        color: #fff !important;
    }
    `
  ],
})
export class FormlyCustomButton extends FieldType {
  onClick(event: Event) {
    if (this.to.onClick) {
      this.to.onClick(event);
    }
  }
}
