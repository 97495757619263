import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-accordion',
  template: `
      <mat-accordion>
      <ng-container *ngFor="let panel of field.fieldGroup; let i = index">
        <mat-expansion-panel 
          [expanded]="panel.templateOptions.expanded" 
           (opened)="onPanelOpened(i)"
          (closed)="onPanelClosed(i)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span  class="status-indicator" class="checkmark">
              <ng-container [ngSwitch]="isSectionValid(i)">
                <mat-icon *ngSwitchCase="'valid'" class="valid">check_circle</mat-icon>
                <mat-icon *ngSwitchCase="'invalid'" class="invalid">cancel</mat-icon>
                <mat-icon *ngSwitchDefault class="default">radio_button_unchecked</mat-icon>
              </ng-container>
              </span>
              {{ panel.templateOptions.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <formly-field *ngFor="let f of panel.fieldGroup" [field]="f"></formly-field>
          <mat-action-row>
            <button mat-button color="primary" *ngIf="i>0 || i>1" (click)="previousAccordion(i)">Previous</button>
            <button mat-button color="primary" *ngIf="i < field.fieldGroup.length - 1" (click)="nextAccordion(i)">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      </ng-container>
      </mat-accordion>
  `,
  styles: [`
    mat-action-row {
      display: flex;
      justify-content: space-between;
    }
    ::ng-deep .mat-expansion-panel {
      margin-bottom: 10px !important;
    }
    ::ng-deep .mat-expansion-panel-header-title{
        font-weight: 700 !important;
    }
    ::ng-deep .mat-action-row {
        padding: 16px 8px 16px 8px !important;
    }
    ::ng-deep .mat-action-row .mat-button-base, .mat-action-row .mat-mdc-button-base {
        margin-left: 0px !important;
    }
    ::ng-deep .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
        color: #3f51b5 !important;
        font-weight: 600 !important;
    }
    .checkmark {
      color: green;
      font-weight: bold;
      margin-right: 6px;
      vertical-align: text-bottom;
    }
    .status-indicator {
        margin-left: 10px;
        font-size: 18px;
        vertical-align: middle;
      }
      .mat-icon.valid {
  color: green;
}
.mat-icon.invalid {
  color: red;
}
.mat-icon.default {
  color: lightgray;
    background: lightgray;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
  `],
})
export class FormlyAccordionTypeComponent extends FieldType  {

  // shouldDisplayPanel(panel: any, index): boolean {
  //   const description = panel.templateOptions?.description?.toLowerCase();
  //   if(this.model.is_cannabis_inspection === 'Y'){
  //     return description === 'cannabis' || description === 'both';
  //   }else{
  //     return description === 'non_cannabis' || description === 'both';
  //   }
  // }

  onPanelOpened(index: number) {
    this.field.fieldGroup[index].templateOptions.expanded = true;
  }
  
  onPanelClosed(index: number) {
    this.field.fieldGroup[index].templateOptions.expanded = false;
  }

  nextAccordion(index: number) {
    const currentIndex = index
    if (currentIndex !== -1 && currentIndex < this.field.fieldGroup.length - 1) {
      this.field.fieldGroup[currentIndex].templateOptions.expanded = false;
      this.field.fieldGroup[currentIndex + 1].templateOptions.expanded = true;
    }
  }
  
  previousAccordion(index: number) {
    const currentIndex = index
    if (currentIndex !== -1 && currentIndex > 0) {
      this.field.fieldGroup[currentIndex].templateOptions.expanded = false;
      this.field.fieldGroup[currentIndex - 1].templateOptions.expanded = true;
    }
  }

  isSectionValid(index: number): string {
    const section = this.field.fieldGroup[index];
  
    if (!section || !section.fieldGroup) {
      return 'incomplete';
    }

  const requiredFields = section.fieldGroup.filter(field => {
    const control = field.formControl;
    return control?.validator?.({} as any)?.required === true;
  });

  const optionalFields = section.fieldGroup.filter(field => {
    const control = field.formControl;
    return !control?.validator?.({} as any)?.required;
  });

  const allRequiredValid = requiredFields.every(field => field.formControl?.valid);
  const allFieldsEmpty = section.fieldGroup.every(field => {
    const control = field.formControl;
    return !control?.value || control.value === '';
  });

  if (allFieldsEmpty) {
    return 'incomplete';
  }
  return allRequiredValid ? 'valid' : 'invalid';
  }

}
