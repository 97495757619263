import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-row',
  template: `
    <div class="form-row" fxLayout="row" fxLayoutGap="20px" style="margin-right: 0px !important;">
      <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
    </div>
  `,
  styles: [`
    .form-row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
  `]
})
export class FormlyCustomRow extends FieldType {}
