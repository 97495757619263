import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.css']
})
export class UserVerificationComponent implements OnInit {
  templateID: any;
  userForm: FormGroup;
  dynamicSection: any;
  isSubmissionSuccess = false;

  constructor(
   private route: ActivatedRoute
  , private router: Router
  , private _api: ApiService
  , private spinner: NgxSpinnerService
  , private formBuilder: FormBuilder
  , private cookieService: CookieService
  , private sanitizer: DomSanitizer
  , private dialog: MatDialog
  ) {
    this.userForm = formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  ngOnInit(): void {
    this.templateID = this.route.snapshot.paramMap.get('id');
    if(this.templateID){
      this.getTemplateSection();
    }
  }

  getTemplateSection() {
    this.spinner.show();
    const apiData = {
      template_id: this.templateID
    };
    this._api.functionPOST('web/getWebSection', apiData).subscribe((response) => {
     this.dynamicSection = this.sanitizer.bypassSecurityTrustHtml(response['data']['section']);
      this.spinner.hide();
    });
  }

  formValidation(tag: number){
    let firstName = this.userForm.get('firstName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;

    if(!firstName){
      if(tag==1){
        this._api.openSnackBar('Enter First Name', 'Error');
      }
      return;
    }
    if(!lastName){
      if(tag==1){
        this._api.openSnackBar('Enter Last Name', 'Error');
      }
      return;
    }
    if(!email){
      if(tag==1){
        this._api.openSnackBar('Enter Email', 'Error');
      }
      return;
    }
  this.submitUserVerification();

  }

submitUserVerification(){

  let firstName = this.userForm.get('firstName')?.value;
  let lastName = this.userForm.get('lastName')?.value;
  let email = this.userForm.get('email')?.value;

  this.spinner.show();
  const apiData = {
    firstname: firstName,
    lastname: lastName,
    email: email,
    template_id: this.templateID,
    latitude: this.cookieService.get('latitude'),
    longitude:  this.cookieService.get('longitude')

  };
  this._api.functionPOST('web/sendEmailVerification', apiData).subscribe((response) => {
   if(response['success']){
    this.cookieService.set('instance_id', response['data']['df_instance_id']);
    this.cookieService.set('token', response['data']['token']);
    this.cookieService.set('email', response['data']['email']);

    if(response['data']['token']){
      this.router.navigate(['selected-application/'+response['data']['df_instance_id']]);
    }
    else if(response['data']['show_app_status']){
      this.router.navigate(['app-access-verification/'+response['data']['df_instance_id']]);
    }
    else if(response['data']['is_app_inprogress']){
      // Open modal with info
      const dialogRef = this.dialog.open(BasicPopupComponent, {
        maxWidth: '600px'
        , data: {
          pageTitle: "Alert",
          pageType: 'app_in_progress',
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/']);
        }
      });

    }
    else {
      // this._api.openSnackBar(response['data'], 'Success');
      this.isSubmissionSuccess = true;
    }
   }
    this.spinner.hide();
  });
}

goToHome(){
  this.router.navigate(['/']);
}
}
