import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-input',
  template: `
    <!-- <div class="custom-input-wrapper">
      <label *ngIf="to.label" class="custom-label">{{ to.label }}</label>
      <input
        matInput
        type="text"
        [formControl]="formControl"
        [formlyAttributes]="field"
        class="custom-input"
        [placeholder]="to.placeholder"
      />
    </div> -->
    <label *ngIf="to.label" class="custom-label" [innerHTML]="to.label"></label>
    <mat-form-field appearance="fill" class="custom-material-input">
      <!-- <mat-label *ngIf="to.label">{{ to.label }}</mat-label> -->
      <input
        matInput
        [formControl]="formControl"
        [formlyAttributes]="field"
        [placeholder]="to.placeholder"
        [readonly]="to.readonly"
      />
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
    .custom-material-input {
        width: 100%;
      }
      .custom-input-wrapper {
        display: flex;
        flex-direction: column;
      }
      .custom-label {
        margin-bottom: 4px;
      }
      .custom-input {
        margin-top: 8px;
      }
      ::ng-deep .mat-form-field-wrapper {
        margin-bottom: 0px !important;
      }
    `,
  ],
})
export class FormlyCustomInputComponent extends FieldType {}
