import { Component, Input } from '@angular/core';
import { FieldType } from '@ngx-formly/core'

@Component({
  selector: 'app-formly-field-accordion',
  template: `
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel [expanded]="expanded" (opened)="openPanel()" (closed)="closePanel()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ to.title || 'Panel Title' }}
          </mat-panel-title>
          <mat-panel-description *ngIf="to.description" class="d-none d-sm-block">
            <div [ngClass]="getDescriptionClass(to.description)">
              {{ to.description }}
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
        <!-- <mat-action-row>
          <button mat-button color="primary" (click)="nextStep()">Next</button>
        </mat-action-row> -->
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styles: [`
    ::ng-deep .mat-expansion-panel {
      margin-bottom: 10px !important;
    }
    ::ng-deep .mat-expansion-panel-header-description{
        color: #fff !important;
        padding: 10px !important;
        font-weight: 700 !important;
        font-size: 20px !important;
    }
    ::ng-deep .mat-expansion-panel-header-title{
        font-weight: 700 !important;
    }
    .img {
width: 100%;
margin-bottom: 18%;
}
.card-picture {
width: 23%;
margin-bottom: 2%;
}
.container {
min-height: 100%;
width: 100%;
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
flex-basis: 0;
}

::ng-deep .example-headers-align .mat-expansion-panel-header-description {
justify-content: space-between;
align-items: center;
}

mat-form-field {
margin-right: 12px;
}

::ng-deep .example-small-box, .example-large-box {
display: flex;
align-items: center;
justify-content: center;
margin: 16px;
padding: 16px;
border-radius: 8px;
}

.example-small-box {
height: 100px;
width: 100px;
}

.example-large-box {
height: 300px;
width: 300px;
}

.map-box {
display: flex;
align-items: center;
justify-content: center;
height: 150px;
width: 100%;
background-color:lightblue;
}

.media-box {
height: 100px;
width: 100px;
background-color:lightgreen;
display: flex;
align-items: center;
justify-content: center;
margin: 16px;
padding: 16px;
border-radius: 8px;
}

.example-full-width {
width: 100%;
}

  `]
})
export class AccordionTypeComponent extends FieldType {
  @Input() expanded = false;

  openPanel() {
    if (this.to.onOpened) {
      this.to.onOpened();
    }
  }

  closePanel() {
    if (this.to.onClosed) {
      this.to.onClosed();
    }
  }

  nextStep() {
    if (this.to.onNextStep) {
      this.to.onNextStep();
    }
  }
  getDescriptionClass(description: string): string {
    switch (description) {
      case 'Uploaded':
        return 'badge bg-success p-2';
      case 'Pending':
        return 'badge bg-primary p-2';
      case 'Not Applicable':
        return 'badge bg-secondary p-2';
      default:
        return '';
    }
}

}
