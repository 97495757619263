import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-thumbnails',
  template: `
  <div *ngIf="to.images?.length > 0" class="thumbnail-gallery">
    <div *ngFor="let image of to.images; let i = index" class="thumbnail-container">
      <img
        [src]="image['fileurl']"
        class="thumbnail-image"
        (click)="to.onClick ? to.onClick(image) : null" />   
        <button
        class="delete-icon"
        (click)="deleteImage(image); $event.stopPropagation();"
        aria-label="Delete Image">
        <img src="assets/images/deletesmall.svg">
      </button>

        <div class="thumbnail-details">
          <mat-label class="fw-bold" *ngIf="image['img_capture_timestamp']">
            Date & Time
          </mat-label><br>
          <mat-label *ngIf="image['img_capture_timestamp']">
            {{ image['img_capture_timestamp'] | date : 'MMM dd, yyyy, hh:mm:ss a' }}
          </mat-label>
          <hr class="m-2">
          <mat-label class="fw-bold" *ngIf="image['geolocation']">
          Coordinates 
          </mat-label><br>
          <mat-label *ngIf="image['geolocation']">
            {{ image['geolocation'] }}
          </mat-label>
    </div>
  </div>
</div>
  `,
styles: [`
.thumbnail-gallery {
  flex-wrap: wrap;
  gap: 10px;
}

.thumbnail-container {
  display: flex;
}

.thumbnail-image {
  width: 10%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  contain: inline-size;
}

@media screen and (max-width: 768px) {
  .thumbnail-image {
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .thumbnail-image {
    width: 100% !important;
  }
}

.thumbnail-details {
  margin-top: 5px;
  font-size: 12px;
  color: #000;
  margin-left: -15px;
}

.delete-icon {
  position: relative;
  right: 32px;
  background: #fff;
  border: none;
  height: 32px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  width: 32px;
}
  `],
})
export class CustomThumbnailsComponent extends FieldType {

  deleteImage(image: string): void {
    if (typeof this.to.onDelete === 'function') {
      this.to.onDelete(image);
    } else {
      console.error('onDelete function is not defined in templateOptions');
    }
  }
}