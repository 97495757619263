import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-image',
  template: `
    <div *ngIf="to.src">
      <hr>
      <label class="fs-4 fw-bold">{{ to.label }}</label><br>
      <img 
      [src]="to.src" 
      [alt]="to.label" 
      class="img-thumbnail"
      [ngStyle]="{
          objectFit: to.styles?.objectFit || 'contain'
        }"
         />
    </div>
  `,
})
export class ImageTypeComponent extends FieldType {}
// [ngStyle]="{
//   maxWidth: to.styles?.maxWidth || '100%',
//   maxHeight: to.styles?.maxHeight || '100%',
//   objectFit: to.styles?.objectFit || 'contain'
// }"