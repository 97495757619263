import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../shared/popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { Router } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';


type ExpressionFunction = (model: any, formState: any, field?: FormlyFieldConfig) => boolean;

interface FormlyExpressions {
  [key: string]: string | boolean | ExpressionFunction;
}

@Component({
  selector: 'app-dynamic-application-demo',
  templateUrl: './dynamic-application-demo.component.html',
  styleUrls: ['./dynamic-application-demo.component.css']
})
export class DynamicApplicationDemoComponent implements OnInit {
  indJsonForm: any;
  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private _api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.fetch((data) => this.model = data);
    this.assignClickEvent();
  }

  locationControl = new FormControl();
  locationList: string[] = ['Add a Public Location', 'Add Private Location', 'Multi-Location'];
  keywordControl = new FormControl();
  keywordList: string[] = ['Bike', 'Skates', 'Computer'];
  requirementControl = new FormControl();
  requirementsList: string[] = ['No Requirements', 'ID', 'Insurance', 'Background Check', 'Other'];
  value = 'My Awesome Title';
  step = 0;
  selectedshare = 'Stay';
  requirements: FormGroup;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onFileComplete(data: any) {
    console.log(data); 
  }
  
  form = new FormGroup({});
  // model: MyModel = {
  //   dynamicData: []
  // };

  model: {
    dynamicData?: any[], // Optional dynamicData
    status?: 'Pending'
  } = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [
    {
      "type": "tabs",
      "fieldGroup": [ 
        {
          "props": { "label": "Business" },
          "fieldGroup": [
            {
              "type": "section",
              "templateOptions": { 
                "label": "General Business Information",
                "className": "custom-section-header"
              },
              "fieldGroupClassName": "custom-section",
              "fieldGroup": [
                {
                  "type": "row",
                  "fieldGroup": [
                    {
                      "type": "column",
                      "className": "p-right col-md-12",
                      "fieldGroup": [
                       
                        {
                          "key": "businessIncomeTax",
                          "type": "accordion",
                          "templateOptions": {
                            "title": "Business Income Tax Returns",
                            "description": "Uploaded"
                          },
                          "fieldGroup": [
                            {
                              "key": "uploadDocBtn",
                              "type": "custom-button",
                              "templateOptions": {
                                "text": "Upload",
                                "onClick": "openRightSlideModalDoc",
                                "value": "businessIncomeTax",
                                "disabled": false
                              },
                              "className": "ms-3 mb-3 float-end blue_button",
                            },
                            {
                              "key": "notApplicableDocBtn",
                              "type": "custom-button",
                              "templateOptions": {
                                "text": "Not Applicable",
                                "onClick": "openRightSlideModalDocNotApplicable",
                                "disabled": false
                              },
                              "className": "mb-3 float-end",
                            },
                            {
                              "className": "col-md-12",
                              "key": "docTable",
                              "type": "datatable",
                              "templateOptions": {
                                "columns": [
                                  { "key": "fileName", "label": "File" },
                                  { "key": "fileComments", "label": "Comments" },
                                  { "key": "updatedDate", "label": "Updated Date" }
                                ],
                                "data": [
                                  { "fileName": "BusinessFormation.pdf", "fileComments": "This is test document.", "updatedDate": "Oct 20,  2024 11:40P M" },
                                  { "fileName": "EIN.pdf", "fileComments": "This is test document.", "updatedDate": "Oct 20,  2024 11:40 PM" }
                                ]
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
   ]
  
  ngOnInit(): void {
    this.bindFunctionsToTemplateOptions(this.fields);
    // this.fields = this.bindHideExpressions(this.fields);
    this.fields = this.bindExpressionProperties(this.fields);
    this.getCountryList();

    this.indJsonForm = [
      {
        "fieldGroup": [
          {
            "type": 'section',
            "templateOptions": { 
              "label": 'Beneficial Owner',
              "className": 'custom-section-header',
            },
            "fieldGroupClassName": 'custom-section',
            "fieldGroup": [
              {
                "type": 'row',
                "fieldGroup": [

                  {
                    "type": 'column',
                    "className": 'col-md-6 custom-tooltip-class',
                    "fieldGroup": [
                      {
                        "key": "firstName",
                        "type": "input",
                        "templateOptions": {
                          "label": "First Name",
                          "required": true
                        },
                        "validation": {
                            "messages": {
                              "required": "First Name is required."
                            }
                          }
                      },
                    ],
                  },
                  
                  {
                    "type": 'column',
                    "className": 'col-md-6',
                    "fieldGroup": [
                      {
                        "key": "middleInitial",
                        "type": "input",
                        "templateOptions": {
                          "label": "Middle Initial",
                          "required": true
                        },
                        "validation": {
                            "messages": {
                              "required": "Middle Initial is required."
                            }
                          }
                      },
                    ],
                  },
                  {
                    "type": 'column',
                    "className": 'col-md-6',
                    "fieldGroup": [
                      {
                        "key": "lastName",
                        "type": "input",
                        "templateOptions": {
                          "label": "Last Name",
                          "required": true
                        },
                        "validation": {
                            "messages": {
                              "required": "Last Name is required."
                            }
                          }
                      },
                    ],
                  },
                  {
                    "type": 'column',
                    "className": 'col-md-6 custom-tooltip-class',
                    "fieldGroup": [
                      {
                        "key": "suffix",
                        "type": "input",
                        "templateOptions": {
                          "label": "Suffix"
                        }
                      },
                    ],
                  },
                  {
                    "type": 'column',
                    "className": 'col-md-6',
                    "fieldGroup": [
                      {
                        "key": "responsibility",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Do you have significant responsibility for controlling, managing, or directing the legal business entity listed in this application?",
                          "required": true,
                          "radioOptions": [
                            { "value": "Yes", "label": "Yes" },
                            { "value": "No", "label": "No" }
                          ]
                        },
                        "validation": {
                            "messages": {
                              "required": "Significant responsibility is required."
                            }
                          },
                        "className": 'custom-radio'
                      },
                    ],
                  },
                  {
                    "type": 'column',
                    "className": 'col-md-6',
                    "fieldGroup": [
                      {
                        "key": "ownership25Percent",
                        "type": "custom-radio",
                        "templateOptions": {
                          "label": "Do you own 25% or more of the business listed in this application?",
                          "required": true,
                          "radioOptions": [
                            { "value": "Yes", "label": "Yes" },
                            { "value": "No", "label": "No" }
                          ]
                        },
                        "validation": {
                            "messages": {
                              "required": "Business listed in this application is required."
                            }
                          },
                        "className": 'custom-radio'
                      },
                    ],
                  },
                  {
                    "type": 'column',
                    "className": 'col-md-6 custom-tooltip-class mt-4',
                    "fieldGroup": [
                      {
                    "key": "accountSigner",
                    "type": "custom-radio",
                    "templateOptions": {
                      "label": "Account Signer?",
                      "required": true,
                      "radioOptions": [
                        { "value": "yes", "label": "Yes" },
                        { "value": "no", "label": "No" }
                      ]
                    },
                    "validation": {
                            "messages": {
                              "required": "Account Signer is required."
                            }
                          },
                        "className": 'custom-radio'
                  }
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 mt-4',
                  "fieldGroup": [
                    {
                      "key": "ownershipPercentage",
                      "type": "input",
                      "templateOptions": {
                        "label": "% Ownership of the Business",
                        "type": "number",
                        "required": true
                      },
                      "validation": {
                          "messages": {
                            "required": "% Ownership of the Business is required."
                          }
                        },
                      "expressionProperties": {
                        'templateOptions.disabled': 'model.accountSigner !== "yes"'
                      }
                      // "hideExpression": "!model.accountSigner || model.accountSigner === 'no'"
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "jobTitle",
                      "type": "input",
                      "templateOptions": {
                        "label": "Job Title",
                        "required": true
                      },
                      "validation": {
                          "messages": {
                            "required": "Job Title is required."
                          }
                        }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "mothersMaidenName",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mother’s Maiden Name",
                        "required": true
                      },
                      "validation": {
                          "messages": {
                            "required": "Mother’s Maiden Name is required."
                          }
                        }
                    },
                  ],
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "birthDate",
                      "type": "input",
                      "templateOptions": {
                        "label": "Birth Date",
                        "type": "date",
                        "required": true
                      },
                      "validation": {
                          "messages": {
                            "required": "Birth Date is required."
                          }
                        }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "phone",
                      "type": "phone",
                      "templateOptions": {
                        "label": "Phone",
                        "required": true,
                        "mask": "(000) 000-0000",
                          "tooltip": "Phone Number is required."
                      },
                      "validation": {
                        "messages": {
                          "required": "Phone is required."
                        }
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "email",
                      "type": "input",
                     "templateOptions": {
                        "label": "Email",
                        "type": "email",
                        "required": true,
                        "pattern": "^\\w+@[a-zA-Z_]+?\\.[a-zA-Z]{2,3}$",
                        "patternMessage": "Please enter a valid email address."
                      },
                      "validation": {
                        "messages": {
                          "required": "Email ID is required",
                          "pattern": "Please enter a valid email address."
                        }
                      }

                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "ssn",
                      "type": "input",
                      "templateOptions": {
                        "label": "Social Security Number",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Social Security Number is required."
                        }
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "citizenship",
                      "type": "select",
                      "templateOptions": {
                        "label": "Citizenship",
                        "required": true,
                        "radioOptions": [
                          { "value": "US Citizen", "label": "US Citizen" },
                          { "value": "Permanent Resident", "label": "Permanent Resident" },
                          { "value": "Non-Permanent Resident", "label": "Non-Permanent Resident" }
                        ]
                      },
                      "validation": {
                        "messages": {
                          "required": "Citizenship is required."
                        }
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "countryOfOrigin",
                      "type": "select",
                      "templateOptions": {
                        "label": "If not a US Citizen, indicate country of Origin",
                        "required": true,
                        "radioOptions": [
                          { "value": "Country1", "label": "Country1" },
                          { "value": "Country2", "label": "Country2" }
                        ]
                      },
                      "validation": {
                        "messages": {
                          "required": "Country of Origin is required."
                        }
                      }
                    },
                  ],
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "authorizedSigner",
                      "type": "custom-radio",
                      "templateOptions": {
                        "label": "Is this individual an authorized signer?",
                        "required": true,
                        "radioOptions": [
                          { "value": "Yes", "label": "Yes" },
                          { "value": "No", "label": "No" }
                        ]
                      },
                      "validation": {
                        "messages": {
                          "required": "Authorized signer is required."
                        }
                      },
                      "className": 'custom-radio'
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "residentialAddress1",
                      "type": "input",
                      "templateOptions": {
                        "label": "Residential Street Address Line 1",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Residential Street Address Line 1 is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "residentialAddress2",
                      "type": "input",
                      "templateOptions": {
                        "label": "Residential Street Address Line 2"
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "residentialCity",
                      "type": "input",
                      "templateOptions": {
                        "label": "Residential City",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Residential City is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "residentialState",
                      "type": "select",
                      "templateOptions": {
                        "label": "Residential State",
                        "required": true,
                        "radioOptions": []
                      },
                      "validation": {
                        "messages": {
                          "required": "Residential State is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "residentialZip",
                      "type": "input",
                      "templateOptions": {
                        "label": "Residential Zip",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Residential Zip is required."
                        }
                      }
                    }
                  ]
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "residentialCounty",
                      "type": "input",
                      "templateOptions": {
                        "label": "Residential County",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Residential County is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "residentialCountry",
                      "type": "select",
                      "templateOptions": {
                        "label": "Residential Country",
                        "required": true,
                        "radioOptions": [],
                        "changeFunction": "onResidentialCountryChange"
                      },
                      "validation": {
                        "messages": {
                          "required": "Residential County is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "sameAsMailing",
                      "type": "custom-radio",
                      "templateOptions": {
                        "label": "Is the residential address the same as the mailing address?",
                        "required": true,
                        "radioOptions": [
                          { "value": "Yes", "label": "Yes" },
                          { "value": "No", "label": "No" }
                        ]
                      },
                      "validation": {
                        "messages": {
                          "required": "At least one is required."
                        }
                      },
                      "className": 'custom-radio'
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "photoIdNumber",
                      "type": "input",
                      "templateOptions": {
                        "label": "Photo ID Number",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Photo ID Number is required."
                        }
                      },
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 mt-4',
                  "fieldGroup": [
                    {
                      "key": "photoIdIssueDate",
                      "type": "input",
                      "templateOptions": {
                        "label": "Photo ID Issue Date",
                        "type": "date",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Photo ID Issue Date is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 mt-4',
                  "fieldGroup": [
                    {
                      "key": "photoIdExpirationDate",
                      "type": "input",
                      "templateOptions": {
                        "label": "Photo ID Expiration Date",
                        "type": "date",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Photo ID Expiration Date is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 mt-4',
                  "fieldGroup": [
                    {
                      "key": "photoIdIssueDateState",
                      "type": "input",
                      "templateOptions": {
                        "label": "Photo ID State of Issue",
                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Photo ID State of Issue is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 mt-4',
                  "fieldGroup": [
                    {
                      "key": "photoIdIssueDateCountry",
                      "type": "input",
                      "templateOptions": {
                        "label": "Photo ID Country of Issue",

                        "required": true
                      },
                      "validation": {
                        "messages": {
                          "required": "Photo ID Country of Issue is required."
                        }
                      }
                    }
                  ]
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class mt-4',
                  "fieldGroup": [
                    {
                      "key": "mailingAddress",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mailing Address (if different than residential)"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "mailingAddress1",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mailing Street Address Line 1"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "mailingAddress2",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mailing Street Address Line 2"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "mailingCity",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mailing City"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "mailingState",
                      "type": "select",
                      "templateOptions": {
                        "label": "Mailing State",
                        "radioOptions": []
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "mailingZip",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mailing Zip"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                {
                  "type": 'column',
                  "className": 'col-md-6 custom-tooltip-class',
                  "fieldGroup": [
                    {
                      "key": "mailingCounty",
                      "type": "input",
                      "templateOptions": {
                        "label": "Mailing County"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    },
                  ],
                },
                
                {
                  "type": 'column',
                  "className": 'col-md-6',
                  "fieldGroup": [
                    {
                      "key": "mailingCountry",
                      "type": "select",
                      "templateOptions": {
                        "label": "Mailing Country",
                        "radioOptions": [],
                        "changeFunction": "onmailingCountryChange"
                      },
                      "expressionProperties": {
                        "templateOptions.disabled": "model.sameAsMailing === 'Yes'",
                        // "templateOptions.required": "model.sameAsMailing === 'No'"
                      }
                    }
                  ],
                },
                ],
              },
  
            ],
          },
        ],
      },
    ]
    this.form.valueChanges.subscribe(value => {
      console.log('Form value changes:', value);
      this.model = value; // Sync model with form value
      });
  }

  exitApplication(){
    this.router.navigate(['/']).then(() => {
      window.location.reload(); 
    });
  }

  functionMap = {
    openRightSlideModalLic: this.openRightSlideModalLic.bind(this),
    openRightSlideModalInd: this.openRightSlideModalInd.bind(this),
    openRightSlideModalDocNotApplicable: this.openRightSlideModalDocNotApplicable.bind(this),
    openRightSlideModalDoc: this.openRightSlideModalDoc.bind(this),
    onregisteredCountryChange: this.onregisteredCountryChange.bind(this),
    onMailingCountryChange: this.onMailingCountryChange.bind(this),
    fictitiousBusinessName: (model: any, formState: any) => {
      return !!model.fictitiousBusinessName;
    }
  };

  bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
    fields.forEach(field => {
      if (field.fieldGroup) {
        this.bindFunctionsToTemplateOptions(field.fieldGroup);
      } else if (field.templateOptions && field.templateOptions.onClick) {
        const functionName = field.templateOptions.onClick;
        if (this.functionMap[functionName]) {
          field.templateOptions.onClick = this.functionMap[functionName];
        }
      } else if (field.templateOptions && field.templateOptions.changeFunction) {
        const functionName = field.templateOptions.changeFunction;
        if (this.functionMap[functionName]) {
          field.templateOptions.change = this.functionMap[functionName];
        }
      }
      
    });
  }

  bindExpressionProperties(fields: FormlyFieldConfig[]) {
    return fields.map(field => {
      if (field.expressionProperties) {
        Object.keys(field.expressionProperties).forEach(key => {
          const functionName = field.expressionProperties[key] as string;
          if (this.functionMap[functionName]) {
            field.expressionProperties[key] = this.functionMap[functionName];
          }
        });
      }

      if (field.fieldGroup) {
        field.fieldGroup = this.bindExpressionProperties(field.fieldGroup);
      }

      return field;
    });
  }



  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }
  
// get country list
getCountryList() {
  this.spinner.show();
  this._api.functionGET('country/allcountry', {}).subscribe((res) => {
    this.updateFieldOptions('registeredCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));
    this.updateFieldOptions('mailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));

    this.spinner.hide();
  },
    err => {
    });
}

  onregisteredCountryChange(field: FormlyFieldConfig, event: any) {
    console.log('--------------event', event);
    const selectedCountryId = event.value;
    this.loadStateOptions(selectedCountryId);
  }

  loadStateOptions(countryCode: string) {
    this.spinner.show();
    this._api.functionGET('country/cannabisStates?bt_country_id=' + countryCode).subscribe((data: { [x: string]: any[]; }) => {
      this.updateFieldOptions('registeredStateOrProvince', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }
  
  updateFieldOptions(key: string, options: any[]) {
    const field = this.findFieldByKey(this.fields, key);
    if (field && field.templateOptions) {
      field.templateOptions.options = options;
    }
  }


  onMailingCountryChange(field: FormlyFieldConfig, event: any) {
    console.log('--------------event', event);
    const selectedCountryId = event.value;
    this.loadMailingStateOptions(selectedCountryId);
  }

  loadMailingStateOptions(countryCode: string) {
    this.spinner.show();
    this._api.functionGET('country/cannabisStates?bt_country_id=' + countryCode).subscribe((data) => {
      this.updateFieldOptions('mailingState', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }


  openRightSlideModalLic() {
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'lic_tab'},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
       this.model = {
        dynamicData: [...this.model.dynamicData, result]
      };
      // this.model.dynamicData = result;
      this.cdr.detectChanges();
      this.fields[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].templateOptions.data = this.model.dynamicData[0];
      console.log('=====================result', this.model.dynamicData[0]);
    });
  }

  openRightSlideModalInd() {
    console.log('Open Modal clicked');
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: { tabType: 'key_person_tab', indJsonForm: this.indJsonForm, modalWidth:'100'},
        disableClose: true,
      });
  
      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.appendDataToTable(result);
        }
      });
  }

  appendDataToTable(result: any) {
    const individualTableField = this.findFieldByKey(this.fields, 'individualTable');
    if (individualTableField) {
      const currentData = individualTableField.templateOptions?.['data'] || [];
      const newDataEntry: PersonData = {
        first_name: result.firstName || '',
        last_name: result.middleInitial || ''
      };

      this.model.dynamicData = [
        ...currentData,
        newDataEntry
      ];
      if (individualTableField.templateOptions) {
        individualTableField.templateOptions['data'] = this.model.dynamicData || [];
    }
      this.cdr.detectChanges();
    }
  }
  assignClickEvent() {
    // Assign the onClick event handler dynamically
    this.fields.forEach(field => {
      if (field.key === 'uploadDocBtn') {
        field.templateOptions.onClick = () => this.openRightSlideModalDoc(field.templateOptions.value);
      }
    });
  }
  openRightSlideModalDoc(value: any) {
    console.log('===============value', value);
    // const docTab = this.findFieldByKey(this.fields, value);
    // const title = docTab.templateOptions?.title
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'document_tab', docType: 'title', isApplicable: false},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
       this.model = {
        dynamicData: [...this.model.dynamicData, result]
      };
      // this.model.dynamicData = result;
      this.cdr.detectChanges();
      this.fields[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].templateOptions.data = this.model.dynamicData[0];
      console.log('=====================result', this.model.dynamicData[0]);
    });
  }

  openRightSlideModalDocNotApplicable() {
    const docTab = this.findFieldByKey(this.fields, 'businessIncomeTax');
    const title = docTab.templateOptions?.title
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'document_tab', docType: title, isApplicable: true},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
       this.model = {
        dynamicData: [...this.model.dynamicData, result]
      };
      // this.model.dynamicData = result;
      this.cdr.detectChanges();
      this.fields[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].fieldGroup[0].templateOptions.data = this.model.dynamicData[0];
      console.log('=====================result', this.model.dynamicData[0]);
    });
  }

  submit(model: any) {
    console.log(this.model);
    alert(JSON.stringify(this.model));
  }

  fetch(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/100k.json`);

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
  }
}

interface PersonData {
  first_name: string;
  last_name: string;
}

interface MyModel {
  dynamicData: PersonData[];
}

