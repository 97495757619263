import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-section',
  template: `
   <mat-card>
      <mat-card-title [ngClass]="to['className']">{{ to.label }}</mat-card-title>
      <mat-card-content [ngClass]="field['fieldGroupClassName']">
        <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
      </mat-card-content>
    </mat-card>

    <!-- <div class="form-section">
      <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
    </div> -->
  `,
  styles: [`
    .form-section {
      margin-bottom: 20px;
    }
  `]
})
export class FormlyCustomSection extends FieldType {}
