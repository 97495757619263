<section class="mt-3">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 mb-4">
                <mat-card class="cust_card p-0">
                    <div class="d-flex">
                        <div class="col-12 mb-3">
                            <!-- <h2 class="text-center">{{ selectedTemplateName }}</h2> -->
                        </div>
                    </div>
                    <div class="mx-6">
                        <form [formGroup]="form">
                            <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>  
                        </form> 
                       
                        <div class="d-flex">
                            <div class="col-12 mb-3">
                                <button class="float-end me-4 mt-3" mat-raised-button color="secondary" type="submit">Cancel Application</button>
                                <button class="float-end me-4 mt-3" mat-raised-button color="secondary" type="submit" (click)="exitApplication()">Exit</button>
                                <button class="float-end me-4 mt-3" mat-raised-button color="secondary" type="submit">Save for later</button>
                                <button class="float-end blue_button me-4 mt-3" mat-raised-button type="submit" >Continue</button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>


    <mat-accordion class="example-headers-align">
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Step 1
            </mat-panel-title>
            <mat-panel-description>
              What are you sharing?
              <mat-icon>share</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
      
          <mat-form-field>
            <mat-label>I'd like to share:</mat-label>
            <!-- Pull from Firebase / Admin Categories -->
            <mat-select [(value)]="selectedshare">
              <mat-option value="Experience">Experience / Adventure</mat-option>
              <mat-option value="Stay">A Room / Stay</mat-option>
              <mat-option value="Thing">A Thing / Many Things</mat-option>
              <mat-option value="Workshop">A Workshop</mat-option>
              <mat-option value="Skills">My Skills</mat-option>
              <mat-option value="Media">Pictures / Video</mat-option>
              <mat-option value="AR-VR-360">VR / AR / 360</mat-option>
            </mat-select>
          </mat-form-field>
      
      <!-- Filter Questions / List -->
      
      <h3> Additional Questions related to your CATEGORY </h3>
      
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel  hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Filter Title
            </mat-panel-title>
          </mat-expansion-panel-header>
      
          <h3>Filter Description</h3>
      
          <p>Add here Select, Multi Select or Number</p>
      
      
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel  hideToggle>
        
          <h2 style="margin-top:20px" class="mat-h3">Choose from the following amenities</h2>
          <h3 class="mat-h5">Multi Option Description</h3>
            <mat-selection-list>
              <mat-list-option *ngFor="let multiOption of locationList">
                {{multiOption}}
              </mat-list-option>
            </mat-selection-list>
      
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel  hideToggle>
             <mat-expansion-panel-header>
            <mat-panel-title>
              Filter Title
            </mat-panel-title>
          </mat-expansion-panel-header>
      
          <h3>Filter Description</h3>
      
          <p>Add here Select, Multi Select or Number</p>
      
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel hideToggle>
             <mat-expansion-panel-header>
            <mat-panel-title>
              Filter Title
            </mat-panel-title>
          </mat-expansion-panel-header>
      
          <h3>Filter Description</h3>
      
          <p>Add here Select, Multi Select or Number</p>
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
       
      <!-- End Filter Questions List -->
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        
          <mat-expansion-panel-header>
            <mat-panel-title>
              Step 2
            </mat-panel-title>
            <mat-panel-description>
              Description
              <mat-icon>description</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
          <mat-list>
            <mat-form-field class="example-full-width">
              <input matInput type="text" placeholder="Let's give your {{selectedshare}} a title" >
                <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field> 
      
            <mat-form-field class="example-full-width">
              <textarea matInput type="textarea" #message maxlength="256" placeholder="Description"></textarea>
              
              <mat-hint align="start"><strong>Do your best to describe your {{selectedshare}} </strong> </mat-hint>
              <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
            </mat-form-field>
      
          </mat-list>
      
          <mat-action-row>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Step 3
            </mat-panel-title>
            <mat-panel-description>
              Location
              <mat-icon>my_location</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
            <div class="map-box mat-elevation-z4">
                  <mat-icon>navigation</mat-icon>
            </div>
      
            <mat-form-field style="margin-top:20px" class="example-full-width">
              <mat-label>Add A Location</mat-label>
              <mat-select [formControl]="locationControl" multiple>
                <mat-option *ngFor="let location of locationList" [value]="location">{{location}}</mat-option>
              </mat-select>
              <mat-hint align="start"><strong>Users will only see the address of your {{selectedshare}} when you approve their booking.</strong> </mat-hint>
            </mat-form-field>
      
            <mat-list style="margin-top:20px">
              <h3 matSubheader>Location List</h3>
              <mat-list-item>
                <img matListAvatar src="https://picsum.photos/80">
                <h3 matLine> Location Name </h3>
                <p matLine>
                  <span class="demo-2"> Public / Private / Multi Location </span>
                </p>
                <button align="end" mat-button color="dark">EDIT</button>
              </mat-list-item>
            </mat-list>
      
      
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Step 4
            </mat-panel-title>
            <mat-panel-description>
              Availability
              <mat-icon>date_range</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
          <h2>Date Range Picker</h2>
          <a href="https://github.com/SaturnTeam/saturn-datepicker">Saturn Date Picker GitHub</a>
          <a href="https://stackblitz.com/edit/angular-4cfnyl">Stackblitz Examples</a>
          <h2>Guests Module Here</h2>
      
      
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
        <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
          <!-- https://stackblitz.com/edit/angular-material-file-upload -->
          <mat-expansion-panel-header>
            <mat-panel-title>
              Step 5
            </mat-panel-title>
            <mat-panel-description>
              Photos / Media
              <mat-icon>perm_media</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
      
          <mat-tab-group dynamicHeight>
              <mat-tab label="Upload">
                <div class="media-box mat-elevation-z4">
                  <mat-icon>add_a_photo</mat-icon>
                </div>
              </mat-tab>
              <mat-tab label="View Gallery">
                <mat-list>
                  <mat-list-item>
                    <img matListAvatar src="https://picsum.photos/80">
                    <h3 matLine> Media File Name </h3>
                    <p matLine>
                      <span> Metia Type</span>
                      <span class="demo-2"> -- file size </span>
                    </p>
                    <button align="end" mat-button color="dark">EDIT</button>
                  </mat-list-item>
                </mat-list>
              </mat-tab>
            </mat-tab-group>
      
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">Next</button>
          </mat-action-row>
        </mat-expansion-panel>
      
          <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Step 6
              </mat-panel-title>
              <mat-panel-description>
                Requirements
                <mat-icon>fingerprint</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
      
              <mat-form-field class="example-full-width">
                <mat-label>Requirements</mat-label>
                <mat-select [formControl]="requirements" multiple>
                  <mat-option *ngFor="let requirement of requirementsList" [value]="requirement">{{requirement}}</mat-option>
                </mat-select>
                <mat-hint align="start"><strong>Users will need to verify these requirements in order to apply for your {{selectedshare}}  </strong> </mat-hint>
              </mat-form-field>
      
              <mat-list style="margin-top:20px">
              <h3 matSubheader>Requirements List</h3>
              <mat-list-item>
                <img matListAvatar src="https://picsum.photos/80">
                <h3 matLine> No Requirement </h3>
                <button align="end" mat-button color="dark">EDIT</button>
              </mat-list-item>
            </mat-list>
      
            <mat-action-row>
              <button mat-button color="warn" (click)="prevStep()">Previous</button>
              <button mat-button color="primary" (click)="nextStep()">Next</button>
            </mat-action-row>
          </mat-expansion-panel>
      
        <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              FINISH
            </mat-panel-title>
            <mat-panel-description>
              Review &amp; Submit your Listing!
              <mat-icon>flag</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          
          <mat-hint align="start"><strong>Here's a preview of your {{selectedshare}} Listing:</strong> </mat-hint>
          <mat-card class="example-card">
            <mat-card-header>
              <!-- User Avatar -->
              <img mat-card-avatar class="example-header-image" src="https://picsum.photos/80">
              <mat-card-title>SHARE TITLE</mat-card-title>
              <mat-card-subtitle>SHARE CATEGORY / TAGS</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu">
            <mat-card-content>
              <p>
                Share Description: The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
                bred for hunting.
              </p>
            </mat-card-content>
          </mat-card>
      
          <mat-action-row>
            <button mat-button color="warn" (click)="prevStep()">Previous</button>
            <button mat-button color="primary" (click)="nextStep()">SUBMIT</button>
          </mat-action-row>
        </mat-expansion-panel>
      
      </mat-accordion>

</section> 