import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-camera',
  template: `
    <button
      mat-raised-button
      type="button" 
      [class]="to.className"
      [color]="to.color"
      (click)="capturePhoto()"
    >
      {{ to.buttonText || 'Capture Photo' }}
    </button>
  `,
  styles: [
    `
     ::ng-deep button{
      margin-top: 15px !important;
     }
     ::ng-deep .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
        background-color: #0d6efd !important;
        color: #fff !important;
    }
    `
  ],
})
export class CustomCameraComponent extends FieldType {
  capturePhoto() {
    if (this.to.onCapture) {
      this.to.onCapture(this);
    }
  }
}
