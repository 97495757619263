import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';

@Component({
  selector: 'app-formly-custom-lic-table',
  template: `
  <button class="blue_button mb-4 float-end" mat-raised-button (click)="openRightSlideModalLic()">Add License</button>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data']; let i = index">
          <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td>
          <td>
          <!-- <button class="blue_button mr-3" mat-raised-button (click)="editRow(i)">Edit</button> -->
          <!-- <button class="mr-3" mat-raised-button color="danger" (click)="deleteRow(i)">Delete</button> -->
            <!-- <div (click)="openModal(row)">Open Modal</div> -->
          </td>
        </tr>
      </tbody>
    </table>
  `,
})
export class FormlyCustomLicenseTable extends FieldType {
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    super();
  }
  dataSource = [];
  editIndModel = {};
  isEditing = false;
  editingIndex: number | null = null;


  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }
  
  openRightSlideModalLic() {
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'lic_tab'},
      disableClose: true,
    });
  
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('=====================result', result);
      if (result) {
        // Ensure dynamicLicData is an array before using it
        if (!Array.isArray(this.model.dynamicLicData)) {
          this.model.dynamicLicData = []; // Initialize as an empty array if not an array
        }
    
        // Append the result to dynamicLicData
        this.model.dynamicLicData.push(result); // Use push instead of spreading
        // this.appendDataToTable(result);
      }
    });
  }

//   appendDataToTable(result: any) {
//     const dynamicTableField = this.findFieldByKey(this.fields, 'dynamicTable'); // Adjust as necessary
//     if (dynamicTableField) {
//       const currentData = dynamicTableField.templateOptions.data || [];
  
//       // Initialize dynamicLicData if it's undefined
//       if (!Array.isArray(this.model.dynamicLicData)) {
//         this.model.dynamicLicData = [];
//       }
  
//       // Update the model with new data
//       this.model.dynamicLicData.push({
//         first_name: result.firstName,
//         last_name: result.middleInitial // Adjust based on the actual result structure
//       });
  
//       console.log('----------current dynamicLicData', this.model.dynamicLicData);
  
//       // Update the Formly field's templateOptions.data
//       dynamicTableField.templateOptions.data = this.model.dynamicLicData;
      
//       // Trigger change detection if necessary
//       this.cdr.detectChanges(); // If using ChangeDetectorRef to update the UI
//     }
//   }
  
  

}