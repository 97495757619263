import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-formly-custom-individual-table',
  template: `
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data']; let i = index">
          <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td>
          <td>
          <button class="blue_button" mat-raised-button (click)="editRow(i)">Edit</button>
          <button class="ms-3" mat-raised-button color="danger" (click)="deleteRow(i)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  `,
})
export class FormlyCustomIndividualTable extends FieldType {
  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    , private cookieService: CookieService
  ) {
    super();
  }
  dataSource = [];
  editIndModel = {};
  isEditing = false;
  editingIndex: number | null = null;
  fields: FormlyFieldConfig[] = [];

  deleteRow(index: number): void {
    const currentData = this.model.dynamicIndData;
    currentData.splice(index, 1);
    this.model.dynamicIndData = currentData
  }

  editRow(index: number): void {
    console.log('------------individual edit', index);
    this.isEditing = true;
    this.editingIndex = index;

    this.editIndModel = { ...this.model.dynamicIndData[index] };
    this.openRightSlideModalEditInd(this.editIndModel)
  }

  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }

  getApplication(result){
    this.spinner.show();
    const apiData = {
      instance_id: this.cookieService.get('instance_id'),
      email: this.cookieService.get('email')
    };
    this._api.functionPOST('web/getApplication', apiData).subscribe((response) => {
      this.fields = response['data']['form_json']['master_json'];
      this.spinner.hide();
      this.cdr.detectChanges();
      this.appendDataToIndTable(result);
      // this.bindFunctionsToTemplateOptions(this.fields);
    });
  }
  
  openRightSlideModalEditInd(row: any) {
    console.log('Open Modal clicked');
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: { 
          tabType: 'edit_key_person_tab', 
          // editFields: this.model.dynamicIndData, 
          editModel: { ...row },
          modalWidth:'45'},
        disableClose: true,
      });

      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log('-----------------edit result', result);
          this.appendDataToIndTable(result);
          
        }
      });
  }
  appendDataToIndTable(result: any) {
    const individualTableField = this.findFieldByKey(this.fields, 'individualTable');
    console.log('----------------individualTableField', individualTableField);
    if (individualTableField) {
      this.model.dynamicIndData = [result];
      console.log('------------appendDataToIndTable', this.model.dynamicIndData);
      individualTableField.templateOptions.data = this.model.dynamicIndData;
      this.cdr.detectChanges();
    }
  }
  // appendDataToTable(result: any) {
  //   const individualTableField = this.findFieldByKey(this.fields, 'individualTable');
  //   if (individualTableField) {
  //     const currentData = individualTableField.templateOptions.data || [];
  //     this.model.dynamicData = [...currentData, {
  //       first_name: result.firstName,
  //       last_name: result.middleInitial
  //     }];

  //     console.log('----------current data', this.model.dynamicData);
  //     individualTableField.templateOptions.data = this.model.dynamicData;
  //     this.cdr.detectChanges();
  //   }
  // }

}