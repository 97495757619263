import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  dynamicFormList: any;
  selectedIndex = 0;
  showChildComponent = false;
  selectedTemplateID: any;
  // public lat;
  // public lng;
  // zoom;

  constructor(
    private _api: ApiService
  , private spinner: NgxSpinnerService
  , private router: Router
  , private cookieService: CookieService
  ) {}

ngOnInit(): void {
  // this.getLocation();
  this.deleteAllCookies();
  this.getDynamicForms();
  }

  // getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       this.lat = position.coords.latitude;
  //       this.lng = position.coords.longitude;
  //       this.zoom = 16;
  //       this.cookieService.set('latitude', this.lat);
  //       this.cookieService.set('longitude', this.lng);
  //       console.log("position", position)
  //     });
  //   }else{
  //     console.log("User not allowed")
  //   }
  // }

  deleteAllCookies() {
    console.log('-------------');
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

getDynamicForms() {
  this.spinner.show();
  const apiData = {
    page: 1,
    limit: 10,
    sortorder: "DESC",
    sortcolumn: "created",
  };
  this._api.functionPOST('web/getWebActiveTemplatesList', apiData).subscribe((response) => {
    this.dynamicFormList = response['data']['data'];
    this.spinner.hide();
  });
}

goToApp(event: any) {
  this.router.navigate(['user-submission-form/' + event + '']);
}

}

