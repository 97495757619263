<section class="mt-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 mb-4" *ngIf="dynamicSection">
                <mat-card class="cust_card">
                    <mat-card-content>
                        <p [innerHtml]="dynamicSection" ></p>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-sm-6 col-xs-12 col-lg-6 col-md-6 mb-4 offset-md-3" *ngIf="!isSubmissionSuccess">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <p>We just need to verify a few details about the primary applicant before we begin the application process.</p>
                        <form  [formGroup]="userForm">
                            <mat-form-field class="col-12">
                                <input matInput placeholder="First Name" name="First Name" formControlName="firstName" required>
                                </mat-form-field>
                                <div class="close-btn right-3" *ngIf="userForm.value.firstName"
                                    (click)="userForm.patchValue({firstName :''});formValidation(0)">
                                </div>

                                <mat-form-field class="col-12">
                                    <input matInput placeholder="Last Name" name="Last Name" formControlName="lastName" required>
                                </mat-form-field>
                                <div class="close-btn right-3" *ngIf="userForm.value.firstName"
                                    (click)="userForm.patchValue({lastName :''});formValidation(0)">
                                </div>

                                <mat-form-field class="col-12">
                                    <input matInput placeholder="Email" name="Email" formControlName="email" required>
                                </mat-form-field>
                                <div class="close-btn right-3" *ngIf="userForm.value.firstName"
                                    (click)="userForm.patchValue({email :''});formValidation(0)">
                                </div>
                            
                            <button mat-raised-button class="blue_button float-end mb-5" (click)="formValidation(1)">Submit</button>
                            <button mat-raised-button class="float-end mb-5 me-4" (click)="goToHome()">Cancel</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>


            <div class="col-sm-6 col-xs-12 col-lg-6 col-md-6 mb-4 offset-md-3" *ngIf="isSubmissionSuccess">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <img src="assets/images/approve.svg" class="center mb-3" alt="Approved" height="40" />
                       <h5>An email has been sent to the provided address. Please check your inbox and verify the email.</h5>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</section>