import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-multi-select',
  template: `
  <div class="form-group">
      <label [innerHTML]="to.label" [ngClass]="{ 'required-label': to.required }"></label>
    </div>
    <!-- <label *ngIf="to.label" class="custom-label">{{ to.label }}</label> -->
    <mat-form-field appearance="fill" class="custom-multi-select">
      <!-- <mat-label>{{ to.label }}</mat-label> -->
      <mat-select
        [formControl]="formControl"
        [formlyAttributes]="field"
        [multiple]="true"
        [placeholder]="to.placeholder"
        (selectionChange)="to.selectionChange && to.selectionChange(field, $event)">
        <mat-option
          *ngFor="let option of to.options"
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="showError">
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>
    </mat-form-field>
  `,
  styles: [
    `
      .custom-multi-select {
        width: 100%;
      }
      .required-label::after {
      content: '*';
      color: red;
      margin-left: 4px;
    }
    `,
  ],
})
export class FormlyCustomMultiSelectComponent extends FieldType {}
