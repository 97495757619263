<section class="section">   
    <div class="container mt-3 mb-5">
        <div class="row ">
            <div class="col-md-12 mt-4 text-center mb-3">
                <h5>To access and complete your saved application, please enter the following information:</h5>
            <div class="col-md-6 offset-md-3 mt-3">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <form  [formGroup]="userForm">
                            <mat-form-field class="col-12">
                                <input matInput placeholder="Last Name" name="Last Name" formControlName="lastName" required>
                            </mat-form-field>
                            <div class="close-btn right-3" *ngIf="userForm.value.lastName"
                                (click)="userForm.patchValue({lastName :''});formValidation(0)">
                            </div>
                                
                            <mat-form-field class="col-12">
                                <input matInput placeholder="Email" name="Email" formControlName="email" required>
                            </mat-form-field>
                            <div class="close-btn right-3" *ngIf="userForm.value.email"
                                (click)="userForm.patchValue({email :''});formValidation(0)">
                            </div>
                                
                            <mat-form-field class="col-12">
                                <input matInput placeholder="Mother's Maiden Name" name="Mother's Maiden Name" formControlName="motherSirName" required>
                            </mat-form-field>
                            <div class="close-btn right-3" *ngIf="userForm.value.motherSirName"
                                (click)="userForm.patchValue({motherSirName :''});formValidation(0)">
                            </div>
                
                            <button mat-raised-button class="blue_button float-end mb-5" (click)="formValidation(1)">Continue</button>
                            <button mat-raised-button class="float-end mb-5 me-4" (click)="goToHome()">Cancel</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
            </div>
        </div>
        <div  class="col-md-12 text-center">
            <h5>Note: Your information has been saved from a previous session </h5>
        </div>
    </div>
</section>