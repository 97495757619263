import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-checkbox',
  template: `
    <div class="form-check">
      <input
        type="checkbox"
        [formControl]="formControl"
        [formlyAttributes]="field"
        class="form-check-input mt-4 mb-3 checkbox-size"
      />
      <label class="form-check-label mt-4 mb-3 ms-3" [innerHTML]="to.labelHtml ? to.label : ''"></label>
      <span *ngIf="to.required && !formControl?.value" class="text-danger"> *</span>
    </div>
  `,

styles: [`
    .checkbox-size {
      width: 20px;
      height: 20px;
    }
  `]

})
export class FormlyFieldCheckboxComponent extends FieldType {
  constructor() {
    super();
  }
}
