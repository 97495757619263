// formly-info-wrapper.component.ts
import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-tooltip-wrapper',
  template: `
    <div>
      <label>{{ to.label }}</label>
      <span *ngIf="to['tooltip']" matTooltip="{{ to['tooltip'] }}">
        <i class="material-icons">info</i>
      </span>
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
  styles: [`
    .tooltip-wrapper {
      display: flex;
      align-items: center;
    }
    .tooltip-icon {
      margin-left: 8px;
    }
    ::ng-deep .mat-form-field-wrapper {
    width: 100%;
  }
  
  ::ng-deep .mat-form-field-flex {
    width: 100%;
  }
  `]
})
export class FormlyTooltipWrapper extends FieldWrapper  {}
