import { Injectable } from "@angular/core";
import { BACKEND, tokenType, urls } from "../config";
import { from, throwError, Observer, fromEvent, merge } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, pipe, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import {
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UnAuthComponent } from "../shared/popups/un-auth/un-auth.component";
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  isOpen: boolean = false;
  constructor(
    private http: HttpClient, 
    private reCaptchaV3Service: ReCaptchaV3Service, 
    public snackBar: MatSnackBar,
    private router: Router,
    private dialogRef: MatDialog,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
  ) {}

  getHeaderData(data?: any): Observable<any> {
    return new Observable((observer) => {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: tokenType + this.cookieService.get("token"),
        }),
      };
      const apiLink = BACKEND + 'web/getWebHeaderFooter';

      this.reCaptchaV3Service.execute(environment.reCaptchaSiteKey, 'importantAction', (captcha_token: string) => {
        // Include the reCAPTCHA token in the API request
        const requestData = {
          ...data,
          captcha_token
        };
        this.http.post(apiLink, requestData, httpOptions).pipe(
          map((response) => this.checkResponse(response)),
          catchError((error) => throwError(this.handleError(error)))
        ).subscribe({
          next: (response) => observer.next(response),
          error: (error) => observer.error(error),
          complete: () => observer.complete(),
        });
      });
    });
    
    
    
    
    
    
    
    
    // this.http.get('web/getWebHeaderFooter'); // Replace with your actual API URL
  }


    // ToasterSnack
    openSnackBar(message: string, type: string, duration?: number) {
      const config = new MatSnackBarConfig();
      config.duration = 8000;
      if (duration) {
        config.duration = duration;
      }
      config.politeness = "assertive";
      if (type === "Error") {
        config.panelClass = ["RED_BACKGROUND"];
      } else {
        config.panelClass = ["GREEN"];
      }
      // else{
      //   config.panelClass = ['ORANGE'];
      // }
      this.snackBar.open(message, "x", config);
    }

  // Handler
  checkResponse(response: any) {
    // this.spinner.hide();
    //
    const results = response;
    //
    if (results["success"]) {
      return results;
    } else {
      //
      // throw new Error(results);
      return { error: results["error"] };
    }
  }
  handleError(error: any) {
    if(error.error.errorDescription){
      error.error.error =  error.error.errorDescription;
    }
    let isOffline;
    this.spinner.hide();
    this.createOnline$().subscribe((status) => {
      isOffline = status;
    });
    switch (error.status) {

      case 0:
      case 408:
        isOffline && this.openSnackBar("Try again", "Error");
        break;
      case 401: 
        if (this.router.url.includes("/createaccount?")) {
          this.dialogRef.closeAll();
          // this.showUnauthMessage("Invite has been cancelled");
        }
        if (this.router.url.includes("/invite-user?") && error["error"]["error"]=='UnAuthorized - Invalid or cancelled request') {
          this.dialogRef.closeAll();  
          // this.showUnauthMessage('Invalid or Cancelled  Request ');
          break
        }
        if (this.router.url != "/" && isOffline) {
          if (!this.isOpen) {
            this.dialogRef.closeAll();
            // this.showUnauthMessage("Session Timeout / Unauthorized");
          }
        }
        
        break;
      case 455:
        if (!this.isOpen && isOffline) {
          this.dialogRef.closeAll();
          // this.showUnauthMessage(
          //   "Link Expired , Please Contact StandardC Admin"
          // );
        }
        break;
      case 453:
      case 452:
        // ignore messages
        break;
      case 454:
        isOffline &&
          this.openSnackBar(error["error"]["error"]["message"], "Error");
        break;
      case 0:
          this.openSnackBar('No Internet Connection Available', 'Error');
          break;
      case 403:
        isOffline && this.openSnackBar(error["error"]["error"], "Error");
        // this.router.navigate(['dashboard']);
        break;
      default:
        isOffline && this.openSnackBar(error["error"]["error"], "Error");
        break;
    }
    return error["error"];
  }

  createOnline$() {
    return merge<any>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
}
