import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';


@Component({
  selector: 'app-formly-custom-checkbox',
  templateUrl: './formly-custom-checkbox.component.html',
  styleUrls: ['./formly-custom-checkbox.component.css']
})
export class FormlyCustomCheckboxComponent extends FieldType implements OnInit {
  // Initialize the formControl value if it's undefined
  ngOnInit() {
    if (!Array.isArray(this.formControl.value)) {
      this.formControl.setValue([]);
    }
  }

  isChecked(option) {
    const value = this.formControl.value;
    return Array.isArray(value) && value.includes(option.value);
  }

  onChange(option, event) {
    const value = this.formControl.value || [];

    if (event.checked) {
      // Add the selected option if it's not already present
      this.formControl.setValue([...value, option.value]);
    } else {
      // Remove the unselected option
      this.formControl.setValue(value.filter(item => item !== option.value));
    }
  }

}
