import { Component, ViewChild, TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FieldArrayType } from '@ngx-formly/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-formly-custom-doc-table',
  template: `
   <!-- <ngx-datatable
      class="bootstrap"
      [rows]="rows"
      [columns]="to.columns"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [columnMode]="'force'">
    </ngx-datatable> -->

    <!-- <ngx-datatable
      #myTable
      class="bootstrap"
      [rows]="rows"
      [columns]="to.columns"
      [columnMode]="to.columnMode"
      [rowHeight]="to.rowHeight"
      [headerHeight]="to.headerHeight"
      [footerHeight]="to.footerHeight"
      [limit]="to.limit"
      [scrollbarH]="to.scrollbarH"
      [reorderable]="to.reorderable"
      [externalSorting]="true"
      [selectionType]="'single'">
      <ng-template #defaultColumn ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-column="column">
        <formly-field [field]="getField(field, column, rowIndex)"></formly-field>
      </ng-template>

    </ngx-datatable> -->

    <div class="row">
        <div class="col-md-8">
            <strong class="mb-2">Comments: <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facere, quam veritatis. Eius aliquid architecto</span> </strong>
            <p class="mt-2"><strong>Updated Date: <span>Oct 01, 2024 11:40 PM</span> </strong></p>
        </div>
        <!-- <div class="col-md-4">
          <button class="blue_button ms-3 float-end mb-3" mat-raised-button>Upload</button>
          <button class="float-end mb-3" mat-raised-button color="secondary">Not Applicable</button>
        </div> -->
    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data'];let i = index">
          <td *ngFor="let col of to['columns']">
            {{ row[col.key] || '-' }}
          </td>
          <td>
            <mat-icon class="text-primary" (click)="viewDoc(row['df_document_attach_id'])">visibility</mat-icon>
            <mat-icon class="text-danger ms-3" (click)="deleteDoc(row['df_document_attach_id'])">delete</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  `,
  styles: [
    `
      @media screen and (max-width: 800px) {
        .desktop-hidden {
          display: initial;
        }
        .mobile-hidden {
          display: none;
        }
      }
      @media screen and (min-width: 800px) {
        .desktop-hidden {
          display: none;
        }
        .mobile-hidden {
          display: initial;
        }
      }
    `
  ],
})
export class FormlyCustomDocTable extends FieldArrayType implements OnInit {

  constructor(
    private cdr: ChangeDetectorRef
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    , private cookieService: CookieService
  ) {
    super();
  }

  @ViewChild('defaultColumn') public defaultColumn: TemplateRef<any>;
  @ViewChild('myTable') table: any;

  get rows() {
      return this.to.data || [];
    }
  
    ngOnInit() {
    //   this.to.columns.forEach(column => column.cellTemplate = this.defaultColumn);
    }
  
    // getField(field: FormlyFieldConfig, column: TableColumn, rowIndex: number ): FormlyFieldConfig {
    //   return field.fieldGroup[rowIndex].fieldGroup.find(f => f.key === column.prop);
    // }

toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }
    
  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  public deleteDoc(doc_id: any){
    this.spinner.show();
      const apiData = {
        instance_id: this.cookieService.get('instance_id'),
        email: this.cookieService.get('email'),
        df_document_attach_id: doc_id
      }
      this._api.functionPOST('web/deleteDocument', apiData).subscribe((response) => {
        if(response['success']){
          this._api.openSnackBar(response['data'], 'Success');
          this.getAllDocList();
        }
        this.spinner.hide();
      });
  }

  public logFields(): void {
    console.log('Current field group:', this.field.fieldGroup);
  }


  getAllDocList(){
    this.spinner.show();
      const apiData = {
        instance_id: this.cookieService.get('instance_id'),
        email: this.cookieService.get('email'),
      };
      this._api.functionPOST('web/getAllDocumentByInstanceId', apiData).subscribe((response) => {
        if(response['success']){
          console.log('----------------resdel', response);
          const docTableField = this.findFieldByKey(this.field.fieldGroup, 'docTable');
          if (docTableField) {
            this.model.dynamicDocData = response['Business Income Tax Returns']['v_df_document_attaches'];
            docTableField.templateOptions.data = this.model.dynamicDocData;
            this.cdr.detectChanges();
          }
        }
        this.spinner.hide();
      });
  }

  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }

  viewDoc(doc_id: any){

    // if(data.av_overall_infected=='Y'){
    //   this._api.openSnackBar("You can't download this file", 'Error');
    //   return;
    // }
    const url = 'web/viewDocument/' + doc_id;
    this._api.downloadFile(url,'?');
  }

}

// export class FormlyCustomDocTable extends FieldType {
//   constructor() {
//     super();
//   }


// }
