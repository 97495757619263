<section class="mt-3">
    <div class="container-fluid p-0">
        <div class="card">
            <h3 class="card-header d-flex justify-content-between align-items-center">
                Site Visit Information
            </h3>
            <div class="card-body">
                <!-- <h2 class="card-title">Site Visit Information</h2> -->
                <form [formGroup]="siteVisitForm">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="fin_name" placeholder="Service Provider" matInput>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="am_business_name" placeholder="Business Name" matInput>
                        </mat-form-field>
                    </div> -->
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="external_user_name" placeholder="Individual Name" matInput>
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <label>Purpose of Inspection</label>
                            <div class="radio-group mt-2" style="display: flex; gap: 15px;">
                            <label class="radio-label text-dark">
                                <input type="radio" formControlName="inspection_purpose" value="initial" required>
                                Initial
                            </label>
                            <label class="radio-label text-dark">
                                <input type="radio" formControlName="inspection_purpose" value="ongoing" required>
                                Ongoing
                            </label>
                            <label class="radio-label text-dark">
                                <input type="radio" formControlName="inspection_purpose" value="other" required>
                                Other
                            </label>
                            </div>
                            <mat-form-field class="mt-3" *ngIf="siteVisitForm.get('inspection_purpose')?.value === 'other'">
                                <input type="text" formControlName="inspection_purpose_other" placeholder="Purpose of Inspection" matInput>
                            </mat-form-field>

                            <div *ngIf="siteVisitForm.get('inspection_purpose')?.invalid && formSubmitted" class="text-danger">
                                Please select an option.
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="col-md-6 mt-3">
                        <div class="form-group">
                            <label>Is this inspection for a cannabis licensed or related business?</label>
                            <div class="radio-group mt-2" style="display: flex; gap: 15px;">
                                <label class="radio-label text-dark">
                                    <input type="radio" formControlName="is_inspection" value="yes" required>
                                    Yes
                                </label>
                                <label class="radio-label text-dark">
                                    <input type="radio" formControlName="is_inspection" value="no" required>
                                    No
                                </label>
                            </div>
                            <div *ngIf="siteVisitForm.get('inspection_purpose')?.invalid && formSubmitted" class="text-danger">
                                Please select an option.
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="inspection_title" placeholder="Inspection Title" matInput>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12" *ngIf="is_instruction">
                        <mat-form-field>
                            <textarea formControlName="inspection_instruction" placeholder="Inspection Instruction" matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="8"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="verify_business_name" placeholder="Business Name" [disabled]="siteVisitForm.get('verify_business_name')?.disabled"  matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="business_dba" placeholder="Business DBA" [disabled]="siteVisitForm.get('business_dba')?.disabled" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="audit_location_physical_address" placeholder="Audit Location Physical Address" [disabled]="siteVisitForm.get('audit_location_physical_address')?.disabled" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="business_corporate_address" placeholder="Business Corporate Address" [disabled]="siteVisitForm.get('business_corporate_address')?.disabled" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" mask="(000) 000-0000" formControlName="business_phone_number" placeholder="Business Phone Number" [disabled]="siteVisitForm.get('business_phone_number')?.disabled" matInput>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-6">
                        <mat-form-field>
                            <input type="text" formControlName="service_provider" placeholder="Service Provider" [disabled]="siteVisitForm.get('service_provider')?.disabled" matInput>
                        </mat-form-field>
                    </div> -->

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Edit Site Visit Data</label>
                            <div class="radio-group mt-2" style="display: flex; gap: 15px;">
                                <label class="radio-label text-dark">
                                    <input type="radio" formControlName="is_edit" value="yes" required>
                                    Yes
                                </label>
                                <label class="radio-label text-dark">
                                    <input type="radio" formControlName="is_edit" value="no" required>
                                    No
                                </label>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- <button class="blue_button float-end ms-3" mat-raised-button type="submit" (click)="updateSiteAddress()" *ngIf="siteVisitForm.get('is_edit')?.value === 'yes'">Submit</button>
                <button class="float-end ms-3" mat-raised-button color="secondary" (click)="cancel()" *ngIf="siteVisitForm.get('is_edit')?.value === 'yes'">Cancel</button> -->
                </form>
                <div class="row mt-5 mb-3">
                    <div class="col-md-12 mt-4">
                        <div class="d-grid gap-2">
                            <button class="blue_button" mat-raised-button type="submit" (click)="goToSiteInspection()">{{ siteVisitButtonLabel }}</button>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="float-end mt-3">
        <mat-label class="fs-6 fw-bold">V:{{version_no}}</mat-label>
    </div>
</section>